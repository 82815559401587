import logo from "./images/810.jpg";
import arrow from "./images/arrow.png";
import tremp from "./images/tremp.jpg";
import boden from "./images/boden.jpg";
import stairs from "./images/stairs.gif";
import pepedawg from "./images/pepedawg.jpg";
import cz from "./images/cz2.png";

import React, { useState, useEffect } from "react";

import "./App.css";

function App() {
  const links = {
    dexscreener: "https://dexscreener.com/solana/91gm5favqvqg8zfpf5rosxzcuvuclbbngccb3firgoxa",
    twitter: "https://twitter.com/dawginu_solana",
    telegram: "https://t.me/dawginu_sol",
    ca: "NOT YET!",
    birdeye: "https://birdeye.so/token/3UnKJiAjqd1pd7yDqsUbbmhhT3Y6i5muY7tNm4AT8zCy?chain=solana",
    photon: "https://solana.com",
    raydium: "https://solana.com",
    solscan: "https://solana.com",
  };

  const images = [
    {
      src: "./images/influencers/ansem.png",
      alt: "Ansem",
      //twitterUrl: "https://twitter.com/blknoiz06",
      //name: "Ansem 🐂🀄️ (@blknoiz06)",
    },
    {
      src: "./images/influencers/iced.png",
      alt: "iced",
      //twitterUrl: "icedknife",
      //name: "Iced (@icedknife)",
    },
    {
      src: "./images/influencers/raj.png",
      alt: "raj",
      //twitterUrl: "rajgokal",
      //name: "raj 🖤 (@rajgokal)",
    },
    {
      src: "./images/influencers/solstice.png",
      alt: "solstice",
      // twitterUrl: "The__Solstice",
      //name: "TheS◎Lstice (@The__Solstice)"
    },
    {
      src: "./images/influencers/naps.png",
      alt: "naps",
      // twitterUrl: "naps000",
      //name: "naps (@naps000)"
    },
    {
      src: "./images/influencers/kook.png",
      alt: "kook",
      // twitterUrl: "naps000",
      //name: "naps (@naps000)"
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  return (
    <div>
      
    </div>
  )
  // return (
  //   <div className=" font-comic-sans p-4">
  //     <div className="flex flex-col flex-row lg:flex-col justify-around mx-auto pt-4 items-center font-comic-sans max-w-7xl space-y-4">
  //       <div>
  //         <img src={logo} alt="logo" className="w-16 rounded-full" />
  //       </div>
  //       <div className="flex space-x-4 flex-wrap lg:flex-nowrap justify-center gap-4">
  //         <a
  //           href={links.dexscreener}
  //           target="_blank"
  //           className="bg-blue-200 px-6 py-3 hover:bg-blue-400 transition"
  //         >
  //           Dexscreener
  //         </a>
  //         <a
  //           href={links.birdeye}
  //           target="_blank"
  //           className="bg-blue-200 px-6 py-3 hover:bg-blue-400 transition"
  //         >
  //           Birdeye
  //         </a>
  //         <a
  //           href={links.photon}
  //           target="_blank"
  //           className="bg-blue-200 px-6 py-3 hover:bg-blue-400 transition"
  //         >
  //           Photon
  //         </a>
  //         <a
  //           href={links.raydium}
  //           target="_blank"
  //           className="bg-blue-200 px-6 py-3 hover:bg-blue-400 transition"
  //         >
  //           Raydium
  //         </a>
  //         <a
  //           href={links.solscan}
  //           target="_blank"
  //           className="bg-blue-200 px-6 py-3 hover:bg-blue-400 transition"
  //         >
  //           Solscan
  //         </a>
  //       </div>
  //       <div className="space-x-8 items-center">
  //         <a href={links.twitter} target="_blank">
  //           <i class="fa-brands fa-twitter text-black text-2xl hover:rotate-12 transition"></i>
  //         </a>
  //         <a href={links.telegram} target="_blank">
  //           <i class="fa-brands fa-telegram text-black text-2xl hover:rotate-12 transition"></i>
  //         </a>
  //       </div>
  //     </div>

  //     <div className="text-center justify-center mx-auto py-20 space-y-4 font-comic-sans">
  //       <h1 className="text-3xl font-bold">
  //         all of dese ppl have dawgs inem, are u going to join dem, anon?
  //       </h1>
  //       <h1 className="text-3xl">intrudoocing dawg inu</h1>
  //       <p>a coin for people with dawgs inem</p>

  //       <a href={links.raydium} target="_blank">
  //         <button className="bg-blue-200 px-6 py-3 mt-4 hover:bg-blue-400 transition">
  //           buy $dawg
  //         </button>
  //       </a>
  //       <div className="slideshow">
  //         <a
  //           href={images[currentIndex].twitterUrl}
  //           target="_blank"
  //           rel="noopener noreferrer"
  //         >
  //           <img
  //             src={images[currentIndex].src}
  //             alt={images[currentIndex].alt}
  //             className="w-96 mx-auto object-cover transition-opacity duration-500 ease-in-out"
  //           />

  //           <a
  //             href={images[currentIndex].twitterUrl}
  //             target="_blank"
  //             rel="noopener noreferrer"
  //             className="block text-center text-blue-500 mt-2"
  //           >
  //             {images[currentIndex].name}
  //           </a>
  //         </a>
  //       </div>
  //     </div>

  //     <img src={arrow} alt="arrow" className="w-12 mx-auto" />

  //     <div className="text-center text-2xl py-24 flex justify-center max-w-7xl mx-auto">
  //       <div className="space-y-2">
  //         <p>well wot does iner dawg mean?</p>
  //         <p>
  //           well iner dawg means u have that stroung mentality and able to werk
  //           well in high presshure stations
  //         </p>
  //         <p>u have dat alpha mentality</p>
  //         <p>u have dat killer instinct</p>
  //         <p>u have dat dawg inu</p>
  //       </div>
  //       <img src={pepedawg} alt="pepedawg" className="w-96 mx-auto" />
  //     </div>

  //     <div className="text-center text-2xl py-24  justify-center max-w-7xl mx-auto">
  //       <h1 className="text-4xl font-bold pb-10">
  //         example of SOME1 wif dawg inem
  //       </h1>
  //       <div className="flex flex-col lg:flex-row">
  //         <img src={cz} alt="cz" className="w-96 mx-auto" />

  //         <div className=" text-lg mt-4 md:mt-0">
  //           <div className="space-y-2">
  //             <p>
  //               this is cz, founder of binance (biggest crypto exchange in the
  //               world)
  //             </p>
  //             <p>
  //               he was the trailblaizer of the crypto world, he expanded and
  //               pushed the boundaries of what crypto could be with his exchange
  //             </p>
  //             <p>
  //               he was in a massive legal settlement with the US authorities for
  //               operating a unlicensed money transfer business
  //             </p>
  //             <p>
  //               he pled guilty to money laundering and other charges and was
  //               forced to step down
  //             </p>
  //             <p>
  //               binance was fined 4.3 billion dollars and cz was fined 50
  //               million dollars
  //             </p>

  //             <a
  //               href="https://www.reuters.com/technology/changpeng-zhao-crypto-king-binance-chief-ousted-us-crimes-2023-11-21/"
  //               className="text-blue-400"
  //               target="_blank"
  //             >
  //               [ read more about it here ]
  //             </a>
  //           </div>

  //           <div className="mt-8">
  //             <p>
  //               the point is... he still influences crypto to this day bcus of
  //               that one thing barking within him
  //             </p>
  //             <p>he has that dawg inem</p>
  //           </div>
  //         </div>
  //       </div>
  //     </div>

  //     <div className="flex justify-center">
  //       <a
  //         className="text-center mx-auto justify-center border border-2 border-black p-8 hover:text-white hover:bg-black"
  //         href={links.solscan}
  //         target="_blank"
  //       >
  //         CONTRACT ADDRESS: {links.ca}
  //       </a>
  //     </div>

  //     <div className="flex flex-col lg:flex-row justify-center lg:space-x-24 items-center py-32 font-comic-sans">
  //       <img src={tremp} alt="tremp" className="w-96" />
  //       <div className="text-center space-y-4 mx-auto justify-center">
  //         <p>
  //           tremp is one of those that have that alpha mentality, that killer
  //           instinct, their inner dawg.
  //         </p>
  //         <p>
  //           boden... he has a lil bit of dawg in him but not as much as tremp
  //         </p>
  //         <p>the broder cant even walk up stairs </p>

  //         <img src="/images/stairs.gif" alt="stairs" className="w-96 mx-auto" />
  //       </div>
  //       <img src={boden} alt="boden" className="w-96" />
  //     </div>

  //     <div className="flex justify-center">
  //       <a
  //         className="text-center mx-auto justify-center border border-2 border-black p-8 hover:text-white hover:bg-black"
  //       >
  //         $DAWG INU is a memecoin with no intrinsic value or expectation of
  //         financial return. There is no formal team or roadmap. the coin is for
  //         entertainment purposes only.
  //       </a>
  //     </div>
  //   </div>
  // );
}

export default App;
